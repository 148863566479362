import http from '../httpConfig';

const getPaymentMethodUrl = (agendaUuid) => new Promise((resolve, reject) => {
  const endpoint = `agenda/${agendaUuid}/agenda_payment/payment_method_url`;

  http.get(endpoint)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      if (
        'response' in err
        && 'data' in err.response
        && 'errors' in err.response.data
      ) {
        reject(err.response.data.errors);
        return;
      }

      reject();
    });
});

export default getPaymentMethodUrl;
