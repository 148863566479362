var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"appointment"},[(!_vm.ui.isLoading || _vm.ui.hasLoaded)?[(_vm.appointments.list.length > 0)?_c('div',{staticClass:"flex flex-row flex-wrap items-center content-start justify-between w-full"},[_vm._l((_vm.appointments.list),function(appointment){return _c('div',{key:("appointment-" + (appointment.id)),staticClass:"appointment-card self-stretch",class:{ 'appointment-card--full': _vm.cardWidth === 'full' }},[_c('div',{staticClass:"appointment-card__status",class:("appointment-card__status--" + (_vm.getLabel(appointment.status)))},[_vm._v(" "+_vm._s(appointment.status)+" ")]),_c('div',{staticClass:"appointment-card__wrapper"},[_c('div',{staticClass:"appointment-card__doctor"},[_vm._v(" "+_vm._s(appointment.doctor)+" ")]),_c('div',{staticClass:"appointment-card__modality"},[_c('span',{staticClass:"appointment-card__modality-label",class:appointment.modality === 'presencial'
                  ? 'appointment-card__modality-label--presencial'
                  : 'appointment-card__modality-label--telemedicina'},[_vm._v(" "+_vm._s(appointment.modality)+" ")])]),_c('div',{staticClass:"appointment-card__specialty"},[_vm._v(" "+_vm._s(_vm._f("toSpecialtyName")(appointment.specialty || ''))+" ")]),_c('div',{staticClass:"appointment-card__date"},[_vm._v(" "+_vm._s(appointment.start)+" ")]),_c('div',{staticClass:"appointment-card__details"},[(!/aguardando pagamento/gi.test(appointment.status))?_c('router-link',{staticClass:"appointment-card__details-link",attrs:{"to":{ name: 'UserAppointmentDetails', params: { id: appointment.id } }}},[_c('icon',{attrs:{"name":"visibility","color":"secondary"}}),_c('span',[_vm._v("ver detalhes")])],1):_vm._e(),(_vm.getAction(
                appointment.status,
                appointment.paymentMethod,
                appointment.dueDate
              ) === 'button')?_c('ui-button',{staticClass:"my-0 !py-1 !text-xs",staticStyle:{"height":"2rem !important"},attrs:{"disabled":_vm.ui.isLoading,"color":"primary","label":appointment.label},on:{"click":function($event){return _vm.handleAppointmentClick(appointment)}}}):_vm._e(),(_vm.getAction(
                appointment.status,
                appointment.paymentMethod,
                appointment.dueDate
              ) === 'label')?_c('div',{staticClass:"text-center text-xs text-white\n                bg-gray-300\n                rounded rounded-full p-1 px-3 m-0\n                cursor-default"},[_vm._v(" em baixa bancária ")]):_vm._e()],1)])])}),(_vm.$route.name !== 'UserAccount' && _vm.showButton)?_c('div',{staticClass:"flex flex-row items-center justify-center w-full"},[_c('div',{staticStyle:{"max-width":"20rem","width":"100%"}},[_c('ui-button',{attrs:{"color":"secondary","label":"Carregar mais","disabled":_vm.ui.isLoading},on:{"click":_vm.nextPage}})],1)]):_vm._e()],2):_vm._e(),(_vm.appointments.list.length === 0)?_c('div',[_c('p',[_vm._v(" Não há consultas para exibir. ")])]):_vm._e()]:_vm._e(),(_vm.qrcode.show)?_c('pix-qr-code-modal',{attrs:{"code":_vm.qrcode.code,"agenda-id":_vm.qrcode.agendaId},on:{"update:code":function($event){return _vm.$set(_vm.qrcode, "code", $event)},"update:agendaId":function($event){return _vm.$set(_vm.qrcode, "agendaId", $event)},"update:agenda-id":function($event){return _vm.$set(_vm.qrcode, "agendaId", $event)},"close":function($event){_vm.qrcode.show = false}}}):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.ui.isLoading)?_c('loader'):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }