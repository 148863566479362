<style lang="scss" scoped>
.pix {
  @apply flex flex-col items-start content-start justify-start;

  &__instructions {
    @apply flex flex-row items-center content-center justify-center;
    align-self: center;

    &-icon {
      @apply flex items-center justify-center;
      @apply rounded rounded-full;
      @apply mr-2 h-10 w-10;
      background: var(--button-primary-bg);

      svg {
        @apply pt-1;
        height: 2rem;
      }
    }
  }

  &__text {
    @apply text-sm;
    color: var(--color-text);

    span {
      color: var(--primary-color);
    }

    &--center {
      @apply w-full;
      text-align: center;
    }
  }

  &__qrcode {
    @apply flex items-center justify-center p-2;
  }

  &__copy-paste {
    @apply flex flex-row items-center justify-between;
    @apply rounded rounded-full p-2 px-4;
    @apply transition duration-100;
    background-color: darken(#f9f9f9, 2%);
    margin: 0 auto;
    max-width: 25rem;
    position: relative;
    width: 100%;

    &:hover {
      @apply transition duration-100;
      background-color: darken(#f9f9f9, 5%);
    }
  }

  &__input {
    @apply p-1 text-sm;
    color: rgb(67, 67, 67);
    background: transparent;
    box-sizing: border-box;
    font-family: monospace;
    height: 100%;
    width: calc(100% - 1.5rem);
  }

  &__icon {
    @apply cursor-pointer;
  }

  &__tooltip {
    @apply transition duration-100;
    @apply rounded shadow;
    @apply text-xs text-center p-1 block;
    background: white;
    position: absolute;
    top: -1.5rem;
    left: calc(100% - 4.5rem);
    width: 15ch;
    z-index: 2;

    &:after {
      @apply block absolute shadow-sm;
      background: white;
      content: "";
      height: 10px;
      right: calc(50% - 5px);
      top: calc(100% - 5px);
      transform: rotate(45deg);
      width: 10px;
      z-index: 0;
    }
  }
}
</style>

<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <section
      v-if="ui.state === 'pending'"
      class="pix"
    >
      <div class="pix__instructions">
        <div class="pix__instructions-icon">
          <icon
            name="pix"
            color="white"
            style="margin-top: -3px"
          />
        </div>

        <p class="pix__text">
          escaneie o QR Code e faça o pagamento em até
          <strong>{{ offsetMinutes }}</strong>
        </p>
      </div>

      <p class="pix__text pix__text--center my-6">
        O não pagamento implicará no cancelamento automático do agendamento.
      </p>

      <div class="pix__qrcode w-full flex items-center justify-center p-2">
        <canvas id="qr-code" />
      </div>

      <p class="pix__text pix__text--center mb-2">
        <strong>Código PIX Copia e Cola</strong>
      </p>

      <div class="pix__copy-paste">
        <input
          :value="paymentMethodUrl"
          type="text"
          class="pix__input"
          onFocus="this.select()"
        >

        <div
          class="relative"
          @mouseenter="ui.showTooltip = true"
          @mouseleave="
            helperText = helperTextFirst;
            ui.showTooltip = false;
          "
          @click="copy"
        >
          <icon
            name="copy"
            color="gray"
            class="pix__icon h-6 mt-1"
          />
        </div>

        <div
          v-show="ui.showTooltip"
          class="pix__tooltip"
        >
          {{ helperText }}
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { parseISO, differenceInSeconds } from 'date-fns';
import QRCode from 'qrcode';
import { PAYMENT_PIX_OFFSET_MINUTES } from '@/data/constants';
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'PixQRCode',

  props: {
    paymentMethodUrl: {
      type: String,
      required: false,
      default: () => '',
    },

    startDate: {
      type: String,
      required: false,
      default: () => (new Date()).toISOString(),
    },

    agendaId: {
      type: String,
      required: false,
      default: () => '',
    },

    hasTimer: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },

  components: {
    Icon,
  },

  data() {
    return {
      helperTextFirst: 'Copiar código',
      helperText: '',
      offsetMinutes: `${PAYMENT_PIX_OFFSET_MINUTES} minutos`,
      ui: {
        showTooltip: false,
        state: null,
      },
      timer: null,
    };
  },

  computed: {
    ...mapGetters('user', ['logged']),

    initialRoute() {
      return this.logged ? 'UserScheduling' : 'AppointmentScheduling';
    },
  },

  watch: {
    'ui.state': {
      deep: false,
      immediate: true,
      handler(state) {
        switch (true) {
          case state === 'pending':
            this.toggleProgressBar();
            break;

          case state === 'timedoud':
            this.handleTimeOut();
            break;

          case state === 'paid':
            if (this.hasTimer) {
              clearTimeout(this.timer);
            }

            this.timer = null;
            this.toggleProgressBar(false);
            break;

          default:
        }
      },
    },
  },

  created() {
    this.helperText = this.helperTextFirst;

    if (this.hasTimer) {
      this.setTimer();
    }

    this.createSourceEvent();

    document.addEventListener(
      'visibilitychange',
      () => {
        if (!document.hidden && this.hasTimer) {
          this.setTimer();
        }
      },
      false,
    );
  },

  mounted() {
    this.ui.state = 'pending';

    this.$nextTick(() => {
      this.makeQRCode();
    });
  },

  destroyed() {
    if (this.hasTimer) {
      clearTimeout(this.timer);
    }

    this.timer = null;
  },

  methods: {
    ...mapActions('ui', [
      'toggleProgressBar',
      'openModalDialog',
      'closeModalDialog',
    ]),

    copy() {
      const input = document.querySelector('.pix__input');
      input.select();

      navigator.clipboard.writeText(input.value)
        .then(() => {
          this.helperText = 'Código copiado!';
        })
        .catch(() => {
          this.helperText = 'ERRO!';
        });
    },

    setTimer() {
      const diffSeconds = differenceInSeconds(new Date(), parseISO(this.startDate));
      const seconds = (PAYMENT_PIX_OFFSET_MINUTES * 60) - diffSeconds;

      clearTimeout(this.timer);
      this.timer = null;

      this.timer = setTimeout(() => {
        this.ui.state = 'timedout';
      }, seconds * 1000);
    },

    createSourceEvent() {
      const topic = {
        topic: `app-drconecta/agenda/${this.agendaId}`,
        event: 'pix-payment',
      };
      const url = new URL(process.env.VUE_APP_MERCURE);
      url.searchParams.append('topic', topic.topic);

      const listener = new EventSource(url);
      listener.onmessage = (response) => this.handlePaymentConfirmation(
        JSON.parse(response.data),
      );
    },

    handleTimeOut() {
      const msg = `O tempo limite para efetuar o pagamento desta consulta
        expirou. Clique em fechar para reiniciar sua compra.`;

      const fn = () => {
        this.$router.push({ name: this.initialRoute });
        this.closeModalDialog();
      };

      this.openModalDialog({
        type: 'alert',
        title: 'Erro',
        text: msg,
        confirmText: 'Fechar',
        size: 'sm',
        fn,
      });
    },

    handlePaymentConfirmation({ event = '', payload = {} } = {}) {
      if (event === 'pix-payment') {
        this.openModalDialog({
          type: 'alert',
          title: payload?.title ?? 'Alerta',
          text: payload?.msg ?? 'Mensagem',
          confirmText: 'Fechar',
          size: 'sm',
          fn: () => {
            this.ui.state = 'paid';
            this.closeModalDialog();
          },
        });
      }
    },

    makeQRCode() {
      const container = document.getElementById('qr-code');
      QRCode.toCanvas(
        container,
        this.paymentMethodUrl,
        (err) => {
          if (err) {
            console.log(err);
          }
        },
      );
    },
  },
};
</script>
