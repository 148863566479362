import http from '../httpConfig';

const setFilters = (filters) => filters.reduce(
  (acc, filter) => {
    if (filter.value) {
      return acc === '?'
        ? `${acc}${filter.filter}=${filter.value}`
        : `${acc}&${filter.filter}=${filter.value}`;
    }

    return acc;
  }, '?',
);

const removeFromArray = (arr, key) => {
  const arrCopy = JSON.parse(JSON.stringify(arr));
  const index = arrCopy.findIndex((item) => item.filter === key);

  if (index > -1) {
    arrCopy.splice(index, 1);
  }

  return arrCopy;
};

const appointments = (headers, id) => {
  const agendaId = id;
  const url = '/user/agenda';

  const getAll = (
    {
      hasPaymentConfirmation = false,
      initialDate = null,
      finalDate = null,
      page = null,
      itensPerPage = null,
      orderBy = null,
      orientation = null,
    } = {},
    isPast = false,
  ) => new Promise((resolve, reject) => {
    let parameters = [
      { filter: 'hasPaymentConfirmation', value: hasPaymentConfirmation },
      { filter: 'initialDate', value: initialDate },
      { filter: 'finalDate', value: finalDate },
      { filter: 'page', value: page },
      { filter: 'itensPerPage', value: itensPerPage },
      { filter: 'orderBy', value: orderBy },
      { filter: 'orientation', value: orientation },
      { filter: 'filterNotIn[a.status][]', value: 'reagendado' },
    ];

    if (isPast) {
      parameters = removeFromArray(parameters, 'initialDate');
      parameters = removeFromArray(parameters, 'finalDate');
      parameters = removeFromArray(parameters, 'hasPaymentConfirmation');
      parameters.push({ filter: 'filterNotIn[a.status][]', value: 'aberto' });
      parameters.push({ filter: 'filterNotIn[a.status][]', value: 'pagamento não aprovado' });
      parameters.push({ filter: 'filterNotIn[a.status][]', value: 'pagamento não concluído' });
      parameters.push({ filter: 'order[a.appointmentStart]', value: 'desc' });
    } else {
      parameters = removeFromArray(parameters, 'finalDate');
      parameters = removeFromArray(parameters, 'hasPaymentConfirmation');
      parameters.push({ filter: 'filterIn[a.status][]', value: 'aberto' });
      parameters.push({ filter: 'filterIn[a.status][]', value: 'aguardando pagamento' });
      parameters.push({ filter: 'order[a.appointmentStart]', value: 'desc' });
    }

    const filters = setFilters(parameters);

    const endpoint = `${url}${filters}`;

    http.get(endpoint, headers)
      .then((res) => {
        const { data, pagination } = res.data;
        resolve({ data, pagination });
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });

  const get = () => new Promise((resolve, reject) => {
    const endpoint = `${url}/${agendaId}`;

    http.get(endpoint, headers)
      .then((res) => resolve(res.data))
      .catch(reject);
  });

  const reschedule = (appointmentDate) => new Promise((resolve, reject) => {
    const endpoint = `${url}/${agendaId}/rescheduling`;

    const data = {
      'user_rescheduling': {
        appointmentStart: appointmentDate,
      },
    };

    http.put(endpoint, data, headers)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err.response.data);
      });
  });

  const cancel = () => new Promise((resolve, reject) => {
    const endpoint = `${url}/${agendaId}`;

    http.delete(endpoint, headers)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });

  return {
    getAll,
    get,
    reschedule,
    cancel,
  };
};

export default appointments;
