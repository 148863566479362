import http from '../httpConfig';

const consultationPrice = (config, partnerId) => {
  const headers = config;
  const endpoint = `/user/doctor/DOCTOR_ID/health_place/${partnerId}/health_place_consultation_price`;

  const getPrice = (doctorId) => new Promise((resolve, reject) => {
    const url = endpoint.replace(/DOCTOR_ID/g, doctorId);

    http.get(url, headers)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (
          typeof err.response.data === 'object'
          && 'errors' in err.response.data
        ) {
          reject(err.response.data.errors);
          return;
        }

        reject(err.response.data);
      });
  });

  return {
    getPrice,
  };
};

export default consultationPrice;
