<template>
  <article class="appointment">
    <template v-if="!ui.isLoading || ui.hasLoaded">
      <div
        class="flex flex-row flex-wrap items-center content-start justify-between w-full"
        v-if="appointments.list.length > 0"
      >
        <div
          v-for="appointment in appointments.list"
          :key="`appointment-${appointment.id}`"
          :class="{ 'appointment-card--full': cardWidth === 'full' }"
          class="appointment-card self-stretch"
        >
          <div
            :class="`appointment-card__status--${getLabel(appointment.status)}`"
            class="appointment-card__status"
          >
            {{ appointment.status }}
          </div>

          <div class="appointment-card__wrapper">
            <div class="appointment-card__doctor">
              {{ appointment.doctor }}
            </div>

            <div class="appointment-card__modality">
              <span
                :class="
                  appointment.modality === 'presencial'
                    ? 'appointment-card__modality-label--presencial'
                    : 'appointment-card__modality-label--telemedicina'
                "
                class="appointment-card__modality-label"
              >
                {{ appointment.modality }}
              </span>
            </div>

            <div class="appointment-card__specialty">
              {{ appointment.specialty || '' | toSpecialtyName }}
            </div>

            <div class="appointment-card__date">
              {{ appointment.start }}
            </div>

            <div class="appointment-card__details">
              <router-link
                v-if="!/aguardando pagamento/gi.test(appointment.status)"
                :to="{ name: 'UserAppointmentDetails', params: { id: appointment.id } }"
                class="appointment-card__details-link"
              >
                <icon
                  name="visibility"
                  color="secondary"
                />
                <span>ver detalhes</span>
              </router-link>

              <ui-button
                v-if="getAction(
                  appointment.status,
                  appointment.paymentMethod,
                  appointment.dueDate,
                ) === 'button'"
                :disabled="ui.isLoading"
                color="primary"
                :label="appointment.label"
                style="height: 2rem !important;"
                class="my-0 !py-1 !text-xs"
                @click="handleAppointmentClick(appointment)"
              />

              <div
                v-if="getAction(
                  appointment.status,
                  appointment.paymentMethod,
                  appointment.dueDate,
                ) === 'label'"
                class="text-center text-xs text-white
                  bg-gray-300
                  rounded rounded-full p-1 px-3 m-0
                  cursor-default"
              >
                em baixa bancária
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="$route.name !== 'UserAccount' && showButton"
          class="flex flex-row items-center justify-center w-full"
        >
          <div style="max-width: 20rem; width: 100%;">
            <ui-button
              color="secondary"
              label="Carregar mais"
              :disabled="ui.isLoading"
              @click="nextPage"
            />
          </div>
        </div>
      </div>

      <div v-if="appointments.list.length === 0">
        <p>
          Não há consultas para exibir.
        </p>
      </div>
    </template>

    <pix-qr-code-modal
      v-if="qrcode.show"
      :code.sync="qrcode.code"
      :agenda-id.sync="qrcode.agendaId"
      @close="qrcode.show = false"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <loader v-if="ui.isLoading" />
    </transition>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { format, parseISO, isAfter } from 'date-fns';
import handleError from '@/mixins/handleError';
import toSpecialtyName from '@/filters/specialtyName';
import appointments from '@/api/user/appointments';
import consultationPrice from '@/api/appointmentScheduling/consultationPrice';
import getPaymentMethodUrl from '@/api/appointmentDetails/paymentMethodUrl';
import UiButton from '@/components/ui/Button.vue';
import Loader from '@/components/ui/Loader.vue';
import Icon from '@/components/ui/Icon.vue';
import PixQrCodeModal from '@/components/appointmentScheduling/paymentMethods/pixQRCodeModal.vue';

export default {
  name: 'MedicalHistoryAppointments',

  mixins: [handleError],

  filters: {
    toSpecialtyName,
  },

  props: {
    items: {
      type: Number,
      required: false,
      default: () => 10,
    },

    cardWidth: {
      type: String,
      required: false,
      default: () => '',
    },

    isPast: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  components: {
    UiButton,
    Loader,
    Icon,
    PixQrCodeModal,
  },

  data() {
    return {
      appointments: {
        api: null,
        list: [],
        pagination: null,
      },
      qrcode: {
        agendaId: '',
        code: '',
        show: false,
      },
      ui: {
        isLoading: false,
        hasLoaded: false,
        currentPage: 1,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['headers']),
    ...mapGetters('partner', ['partner', 'medicalSpecialties']),

    showButton() {
      if (
        this.appointments.pagination
        && 'totalPages' in this.appointments.pagination
      ) {
        return this.ui.currentPage < this.appointments.pagination.totalPages;
      }

      return false;
    },
  },

  created() {
    this.appointments.api = appointments(this.headers);

    this.init();
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar', 'completeProgressTrackerStep']),
    ...mapActions('scheduling', [
      'setAppointment',
      'setAddress',
      'setOffice',
      'setDoctor',
      'setPayment',
      'setReservationStartTime',
    ]),

    init() {
      this.ui.currentPage = 1;
      this.appointments.list = [];
      this.appointments.pagination = null;

      this.fetchData();
    },

    fetchData() {
      const now = new Date();
      const initialDate = format(now, 'yyyy-MM-dd');
      const finalDate = format(now, 'yyyy-MM-dd');

      this.toggleProgressBar();
      this.ui.isLoading = true;

      const params = {
        itensPerPage: this.items,
        page: this.ui.currentPage,
        hasPaymentConfirmation: 1,
        initialDate,
        finalDate,
      };

      this.appointments.api
        .getAll(params, this.isPast)
        .then((response) => {
          const { data, pagination } = response;
          this.appointments.pagination = pagination;

          const newData = this.handleData(data);
          this.appointments.list = [...this.appointments.list, ...newData];
        })
        .finally(() => {
          this.toggleProgressBar(false);
          this.ui.isLoading = false;
          this.ui.hasLoaded = true;
        });
    },

    handleData(data) {
      return data.map((appointment) => {
        const {
          id,
          uuid,
          doctor,
          status,
          'appointment_start': start,
          type: modality,
          'agenda_payment': payment,
        } = appointment;

        const startDate = format(parseISO(start), 'dd/MM/yyyy \'às\' HH:mm');
        const doctorName = ('display_name' in doctor && doctor['display_name'])
          ? doctor['display_name']
          : doctor.user.name;

        return {
          id,
          uuid,
          status,
          specialty: this.getSpecialty(appointment),
          doctor: doctorName,
          modality: /telemedicina/gi.test(modality) ? 'telemedicina' : 'presencial',
          start: startDate,
          paymentMethod: ('payment_method' in payment && payment['payment_method'])
            ? payment['payment_method']
            : null,
          dueDate: ('payment_method' in payment && payment['payment_method'])
            ? payment['payment_method_expiration_date']
            : null,

          ...('payment_method' in payment && /boleto/gi.test(payment['payment_method']))
            && { label: 'Visualizar Boleto' },

          ...('payment_method' in payment && /pix/gi.test(payment['payment_method']))
            && { label: 'Pagar com QR Code' },

          ...('payment_method' in payment && /cart(ã|a)o de cr(é|e)dito/gi.test(payment['payment_method']))
            && { label: 'Realizar pagamento' },
        };
      });
    },

    nextPage() {
      this.ui.currentPage += 1;
      this.fetchData();
    },

    getSpecialty(details) {
      if (
        'medical_specialty' in details
        && details['medical_specialty']
      ) {
        return details['medical_specialty'].name;
      }

      if (
        'doctor_office_medical_service' in details
        && details['doctor_office_medical_service']
        && 'medical_service' in details['doctor_office_medical_service']
      ) {
        return details['doctor_office_medical_service']['medical_service'].name;
      }

      return '';
    },

    getLabel(status) {
      switch (true) {
        case /n(ã|a)o/gi.test(status) || /cancelado/gi.test(status):
          return 'red';

        case /aguardando/gi.test(status):
          return 'yellow';

        case /aberto/gi.test(status):
          return 'blue';

        case /conclu(í|i)do/gi.test(status) || /realizado/gi.test(status):
          return 'green';

        default:
          return 'blue';
      }
    },

    handleAppointmentClick(appointment) {
      if (appointment.paymentMethod === 'BOLETO') {
        this.openBoleto(appointment.uuid);
        return;
      }

      if (appointment.paymentMethod === 'PIX') {
        this.showQRCode(appointment.uuid);
        return;
      }

      this.validateAppointment(appointment.id);
    },

    openBoleto(uuid) {
      this.ui.isLoading = true;
      this.toggleProgressBar();
      getPaymentMethodUrl(uuid)
        .then((url) => {
          window.open(url);
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    showQRCode(uuid) {
      this.ui.isLoading = true;
      this.toggleProgressBar();
      getPaymentMethodUrl(uuid)
        .then((data) => {
          this.qrcode.agendaId = uuid;
          this.qrcode.code = data;
          this.qrcode.show = true;
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    validateAppointment(id) {
      const appointmentGetter = appointments(this.headers, id);
      const priceGetter = consultationPrice(this.headers, this.partner.id);
      let appointment = null;

      this.ui.isLoading = true;
      this.toggleProgressBar();

      appointmentGetter
        .get()
        .then((res) => {
          if (/aguardando pagamento/gi.test(res.status)) {
            appointment = { id, ...res };
            this.setReservationStartTime(res['created_at']);
            return;
          }

          this.handleError(new Error('O tempo de reserva dessa consulta expirou.'));
          this.init();
        })
        .then(() => priceGetter.getPrice(appointment.doctor.id))
        .then((res) => {
          const { price } = res;
          this.createAppointment({ price, ...appointment });
        })
        .catch((err) => {
          this.handleError(err);
          this.init();
        })
        .finally(() => {
          this.ui.isLoading = false;
          this.toggleProgressBar(false);
        });
    },

    createAppointment(appointment) {
      const modality = /(telemedicina|telemedicine)/gi.test(appointment.type)
        ? 'telemedicine'
        : 'presential';

      const specialty = this.medicalSpecialties
        .find((item) => item.id === appointment['medical_specialty'].id && item.modality === modality);

      const date = parseISO(appointment['appointment_start']);

      if (modality === 'presential') {
        this.setAppointmentAddress(appointment['doctor_office'].address);
      }

      this.setAppointment({
        modality,
        specialty,
        date: format(date, 'yyyy-MM-dd'),
        time: format(date, 'HH:mm:ss'),
      });

      this.setOffice({
        id: appointment['doctor_office'].id,
        uuid: null,
      });

      this.setDoctor({
        id: appointment.doctor.id,
        uuid: null,
      });

      this.setPayment({ price: appointment.price });

      this.completeProgressTrackerStep({ step: 1, status: true });
      this.completeProgressTrackerStep({ step: 2, status: true });
      this.completeProgressTrackerStep({ step: 3, status: true });

      this.$router.push({ name: 'UserSchedulingCheckout', params: { id: appointment.id } });
    },

    getAction(status = null, method = null, d = null) {
      // apagar depois - temp porque dueDate de pix vem vazio
      const date = d || (new Date(2022, 1, 1)).toISOString();
      let type = '';

      if (status && method && date) {
        switch (true) {
          case /aguardando pagamento/gi.test(status)
            && /boleto/gi.test(method)
            && isAfter(new Date(), parseISO(date)):
            type = 'button';
            break;

          case /aguardando pagamento/gi.test(status)
            && /pix/gi.test(method)
            && isAfter(parseISO(date), new Date()):
            type = 'button';
            break;

          case /aguardando pagamento/gi.test(status)
            && /cart(ã|a)o de cr(é|e)dito/gi.test(method):
            type = 'button';
            break;

          case /aguardando pagamento/gi.test(status)
            && /boleto/gi.test(method)
            && isAfter(parseISO(date), new Date()):
            type = 'label';
            break;

          default:
            type = '';
        }
      }

      return type;
    },
  },
};
</script>
