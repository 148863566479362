<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <modal @close="close">
      <template v-slot:title>
        Pagar com QR Code
      </template>

      <template v-slot:body>
        <pix-qr-code
          :payment-method-url="code"
          :agenda-id="agendaId"
          :has-timer="false"
          class="my-4"
        />
      </template>

      <template v-slot:footer>
        <div class="flex flex-row items-center justify-end pt-2">
          <div class="w-48">
            <ui-button
              style="height: 2.25rem"
              label="Fechar"
              color="secondary"
              @click="close"
            />
          </div>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import Modal from '@/components/ui/Modal.vue';
import UiButton from '@/components/ui/Button.vue';
import PixQrCode from '@/components/appointmentScheduling/paymentMethods/pixQRCode.vue';

export default {
  name: 'PixQRCodeModal',

  props: {
    code: {
      type: String,
      required: false,
      default: () => '',
    },

    agendaId: {
      type: String,
      required: false,
      default: () => '',
    },
  },

  components: {
    Modal,
    UiButton,
    PixQrCode,
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" >

</style>
